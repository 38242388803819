<template>
  <div class="container-fluid">
    <div class="row card px-half">
      <div class="col-12 px-0">
        <dx-data-grid
          id="pendingBatches"
          ref="pendingBatchesRef"
          :height="'88vh'"
          :data-source="openBatches"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :hover-state-enabled="true"
          :row-alternation-enabled="false"
          @initialized="onInitialized"
          @content-ready="onContentReady"
        >
          <dx-sorting mode="single" />
          <dx-search-panel
            :highlight-search-text="true"
            :search-visible-columns-only="true"
            :visible="true"
            :width="200"
            placeholder="Search..."
          />
          <dx-column data-field="channel" width="auto" cell-template="channelTypeTemplate" />
          <template #channelTypeTemplate="{data}">
            <div class="text-uppercase" :class="resolveChannelType(data.value)">
              {{ data.value }}
            </div>
          </template>
          <dx-column :visible="hasPermission" data-field="accountNo" width="auto" alignment="center" />
          <dx-column data-field="storeName" width="auto" alignment="left" />
          <dx-column data-field="shipmentPlanName" width="auto" cell-template="nameTemplate" />
          <template #nameTemplate="{data}">
            <div>
              <div v-if="hasPermission" class="text-primary" role="button" @click="openBatch(data)">
                {{ data.value }}
              </div>
              <div v-else>
                {{ data.value }}
              </div>
            </div>
          </template>
          <dx-column data-field="batchQuantity" width="auto" alignment="center" />
          <dx-column data-field="skuCount" caption="SKU Count" width="auto" alignment="center" />
          <dx-column data-field="itemQuantity" width="auto" alignment="center" />
          <dx-column data-field="batchStatus" width="auto" cell-template="batchStatusTemplate" alignment="left" />
          <template #batchStatusTemplate="{ data }">
            <div :class="resolveBatchStatus(data.value)" class="text-uppercase">
              {{ data.value }}
            </div>
          </template>
          <dx-column data-field="creationTime" data-type="datetime" />
          <dx-paging :page-size="250" />
          <dx-pager
            :allowed-page-sizes="pagerOptions.allowedPageSizes"
            :show-info="pagerOptions.showInfo"
            :show-navigation-buttons="pagerOptions.showNavigationButtons"
            :show-page-size-selector="pagerOptions.showPageSizeSelector"
            :visible="pagerOptions.visible"
          />
        </dx-data-grid>
      </div>
    </div>
  </div>
</template>

<script>
import GridBase from '@core/dev-extreme/mixins/grid/base'
import store from '@/store'
import router from '@/router'
import tenantService from '@/http/requests/tenant/tenan.settings'
import isEmpty from 'lodash/isEmpty'
import { Notify } from '@robustshell/utils/index'
import managedKeysEnum from '@/enums/managedKeysEnum'
import ShipmentChannelEnum from '@/enums/shipment-channel.enum'
import useStatisticsCardState from './components/useStatisticsState'

export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const queryParams = { ...to.params }
      if (!isEmpty(queryParams)) {
        if (queryParams.channel) {
          vm.fetchOpenBatches(queryParams.channel)
        }
      }
    })
  },
  mixins: [GridBase],
  setup() {
    const {
      openBatches, fetchOpenBatches,
    } = useStatisticsCardState()
    return {
      openBatches,
      fetchOpenBatches,
    }
  },
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
  },
  methods: {
    resolveChannelType(type) {
      if (type === 'wfs') return 'badge badge-primary d-block'
      if (type === 'fba') return 'badge badge-primary d-block'
      return ''
    },
    resolveBatchStatus(status) {
      if (status === 'boxing') return 'badge badge-light-success d-block'
      if (status === 'open') return 'badge badge-light-warning d-block'
      return ''
    },
    openBatch(e) {
      const batchPath = this.generateBatchPath(e.data)
      router.push(batchPath)
    },
    generateBatchPath(data) {
      const {
        channel,
        companyName,
        storeName,
        accountNo,
        storeId,
        companyId,
        batchStatus,
        id,
      } = data

      this.getStorePrinterSetting(storeId)

      let actionType
      if (batchStatus === 'open') {
        actionType = 'batching'
      } else if (batchStatus === 'boxing') {
        actionType = 'boxing'
      }

      let route = {}

      if (channel === ShipmentChannelEnum.FBA.value) {
        const accountInfo = {
          accountNo: accountNo,
          companyId: companyId,
          companyName: companyName,
          storeId: storeId,
          storeName: storeName,
        }
        accountInfo.accountNo = accountInfo.accountNo.toString()
        store.dispatch('fbaShipment/setBatchAccountInfo', accountInfo)
        route = {
          name: 'fba-shipments-workflow',
          params: {
            accountNo: accountInfo.accountNo,
            workflow: actionType,
            batchId: id,
          },
        }
      }

      if (channel === ShipmentChannelEnum.WFS.value) {
        route = {
          name: 'wfs-shipments-workflow',
          params: {
            workflow: 'shipment-plan',
            accountNo: accountNo,
            companyId: companyId,
            storeId: storeId,
            shipmentPlanId: id,
          },
        }
      }

      return route
    },
    // Settings for FBA
    getStorePrinterSetting(storeId) {
      tenantService.findByKeyAndReference(managedKeysEnum.CURRENTSELECTEDPRINTER.key, storeId).then(result => {
        const data = { ...result.data }
        if (data.id) {
          const printer = JSON.parse(data.value)
          const currentSelectedPrinter = {
            LabelId: printer.LabelId,
            LabelName: printer.LabelName,
            Address: printer.Address,
            PrinterName: printer.PrinterName,
            VendorName: printer.VendorName,
            printer: { ...printer.printer },
            manageLabelPrintering: { ...printer.manageLabelPrintering },
          }
          store.dispatch('fbaShipment/setPrinterSettings', currentSelectedPrinter)
          localStorage.setItem('printerSettings', JSON.stringify(currentSelectedPrinter))
        } else {
          Notify.warning('There is no default printer for this store yet.')
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
