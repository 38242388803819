/**
 * Enum for Freight Class.
 * @readonly
 * @enum {{key: string, value: string}}
 */

const ShipmentChannelEnum = Object.freeze({
  FBA: {
    key: 'fba', value: 'fba', text: 'FBA', target: 'amazon',
  },
  MFN: {
    key: 'mfn', value: 'mfn', text: 'MFN', target: 'amazon',
  },
  WFS: {
    key: 'wfs', value: 'wfs', text: 'WFS', target: 'walmart',
  },
  list: (key, query) => {
    if (key && query) {
      return Object.values(ShipmentChannelEnum)
        .filter(value => typeof value === 'object' && value[key] === query)
        .map(value => ({
          value: value.value,
          text: value.text,
        }))
    }
    return Object.values(ShipmentChannelEnum)
      .filter(value => typeof value === 'object')
      .map(value => ({
        value: value.value,
        text: value.text,
      }))
  },
  find: query => Object.values(ShipmentChannelEnum)
    .find(value => typeof value === 'object' && (value.key === query || value.value === query)),
})

export default ShipmentChannelEnum
